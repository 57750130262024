<template>
  <div class="card p-4">
    <div>
      <b-form id="frmCanaisSlack">
        <b-row
          v-for="erro in erros"
          :key="erro.codigo"
          :value="erro.codigo"
          class="mb-1"
        >
          <b-col cols="9">
            <b-form-input v-model="erro.descricao" disabled></b-form-input>
          </b-col>
          <b-col cols="3">
            <b-form-select
              v-model="erro.nome_canal"
              :options="canaisOptions"
              label="Selecione um canal"
            ></b-form-select>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <div
      v-if="erros.length > 0"
      class="d-flex justify-content-end h-100 w-100 mt-4"
    >
      <b-button variant="success" class="b-button-custom" @click="salvarCanais">
        Salvar canais</b-button
      >
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import API_LINKS from "../painel/automacao/api.links.js";
import "moment/locale/pt-br";
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data() {
    return {
      erros: [],
      canais: [],
      canaisOptions: []
    };
  },

  methods: {
    async pegarErros() {
      try {
        const res = await ApiService.get(
          API_LINKS.erros_notificacoes,
          "",
          false
        );
        this.erros = res.data.erros;
      } catch (error) {
        console.error(error);
      }
    },
    async listarCanais() {
      try {
        const res = await ApiService.get(API_LINKS.canais_slack);
        this.canais = res.data.canais;
        this.canaisOptions = this.canais.map((canal) => {
          return {
            value: canal.name,
            text: canal.name
          };
        });
      } catch (error) {
        console.error(error);
      }
    },
    async salvarCanais() {
      try {
        const res = await ApiService.post(API_LINKS.canais_slack, this.erros);
        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "Canais salvos com sucesso!"
        });
      } catch (error) {
        console.error(error);
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: "Erro ao salvar canais!"
        });
      }
    }
  },
  mounted() {
    this.pegarErros();
    this.listarCanais();
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Notificar Erros"
      }
    ]);
  }
};
</script>

<style scoped></style>
